import React from 'react'

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title"> <br/> <span className="home__title-color">Wouter Tack</span></h1>
{/* 
      <div className="home__description">
        <p>Highly motivated, self-taught developer. I have a passion for learning and sharing my knowledge with others as publicly as possible. </p>
      </div>

      <a href="#contact" className="button button--flex">
        Contact Me <i className="uil uil-message button__icon"></i>
      </a> */}

      <h3 className="home__subtitle">Web developer</h3>
      <p className="home__description">I'm currently a 3rd year student, studying Graphic and Digital Media: New Media Development at Artevelde University. 
        I'm very passionate about programming and I'm always looking for new ways to improve my skills.
      </p>

      <a href="#contact" className="button button--flex">
        Say Hello
        <i className="uil uil-message button__icon"></i>
      </a>
    </div>
  )
}

export default Data