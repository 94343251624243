import React from 'react'
import './Footer.css'
import logo from '../../assets/logo.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
      <img src={logo} alt="" className="footer__img" />

    

        <div className="footer__social">
          
          <a href="https://www.github.com/woutertack" className="home__social-icon" target='_blank' rel="noopener noreferrer">
          <i className='bx bxl-github'></i>
          </a>

          <a href="https://www.instagram.com/woutertack" className="home__social-icon" target='_blank' rel="noopener noreferrer">
            <i className='bx bxl-instagram'></i>
          </a>

        
          <a href="https://www.linkedin.com/in/wouter-tack-328530273/" className="home__social-icon" target='_blank' rel="noopener noreferrer">
          <i className='bx bxl-linkedin'></i>
          </a>
        </div>

        <span className="footer__copy">&#169; Wouter Tack. All rights reserved.</span>
      </div>
    </footer>
  )
}

export default Footer