import React from 'react'


const Frontend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Frontend</h3>

      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
          <i class="bx bx-badge-check"></i>

          <div>
            <h3 className="skills__name">HTML/CSS</h3>
            <span className="skills__level">Advanced</span>
          </div>
          </div>

          <div className="skills__data">
          <i class="bx bx-badge-check"></i>

          <div>
            <h3 className="skills__name">JavaScript</h3>
            <span className="skills__level">Advanced</span>
          </div>
          </div>

          <div className="skills__data">
          <i class="bx bx-badge-check"></i>

          <div>
            <h3 className="skills__name">React JS</h3>
            <span className="skills__level">Intermediate</span>
          </div>
          </div>

          </div>
        <div className="skills__group">
          <div className="skills__data">
          <i class="bx bx-badge-check"></i>

          <div>
            <h3 className="skills__name">TypeScript</h3>
            <span className="skills__level">Basic</span>
          </div>
          </div>

          <div className="skills__data">
          <i class="bx bx-badge-check"></i>

          <div>
            <h3 className="skills__name">Wordpress</h3>
            <span className="skills__level">Basic</span>
          </div>
          </div>

          

          </div>
        
      </div>
    </div>
  )
}

export default Frontend