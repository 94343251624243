import React from 'react'

const Social = () => {
  return (
    <div className="home__social">

      <a href="https://www.github.com/woutertack" className="home__social-icon" target='_blank' rel="noopener noreferrer">
      <i className='uil uil-github'></i>
      </a>

      <a href="https://www.instagram.com/woutertack" className="home__social-icon" target='_blank' rel="noopener noreferrer">
        <i className='uil uil-instagram'></i>
      </a>

    
      <a href="https://www.linkedin.com/in/wouter-tack-328530273/" className="home__social-icon" target='_blank' rel="noopener noreferrer">
      <i className='uil uil-linkedin'></i>
      </a>
    </div>
  )
}

export default Social