import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
      <i class='bx bx-briefcase-alt about__icon'></i>
        <h3 className="about__title">Education</h3>
          <span className="about__subtitle">Graphical & Digital Media: New Media Development</span>
      </div>

      <div className="about__box">
      <i class='bx bx-award about__icon'></i>
        <h3 className="about__title">Experience</h3>
          <span className="about__subtitle">+3 Years</span>
        
      </div>
    </div>
  )
}

export default Info